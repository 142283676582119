import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const builderContentFn = /* @__PURE__ */ createRecipe('builder-content', {}, [])

const builderContentVariantMap = {}

const builderContentVariantKeys = Object.keys(builderContentVariantMap)

export const builderContent = /* @__PURE__ */ Object.assign(memo(builderContentFn.recipeFn), {
  __recipe__: true,
  __name__: 'builderContent',
  __getCompoundVariantCss__: builderContentFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: builderContentVariantKeys,
  variantMap: builderContentVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, builderContentVariantKeys)
  },
  getVariantProps: builderContentFn.getVariantProps,
})